<template>
    <div id="data-view" class ="curContainer" style="height:600px;">    
        <div style="margin-top:50px;">
            <i style="margin-left:40px;" class="el-icon-caret-right"></i>
            <a href="http://jmweb.fusewise-info.com/" target="_blank">竞媒监测系统</a>  
            <!-- <a href="http://192.168.223.139:82/" target="_blank">竞媒监测系统</a> -->
        </div> 
    </div>
</template>


<script>
import CommonTable from "@/components/CommonTable";
import { mapGetters } from "vuex";
import MyUploadFileFormItem from "@/components/MyUploadFileFormItem";

export default {
    components: {
        // vueQr
    },
    components: { CommonTable,MyUploadFileFormItem}, 
    computed: { ...mapGetters(["areaStationList"]) },

    data() {
        return {
        }
    },
    watch: {},
    $route: {

    },
    async mounted(){

    },

    methods: {
      

    }
};
</script>

<style lang="less">
  .curContainer {
    /* padding: 30px; */
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}
</style>